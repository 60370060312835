import React, { Component, lazy, Suspense } from "react"
import { BrowserRouter as Router, Navigate, NavLink, Route, Routes } from "react-router-dom"
import "./Dashboard.scss"
import "./content/Content.css"
import { getAPIUrl } from "../util/API";
import CreateUrl from "./content/CreateUrl"
import Search, { Result } from "./components/Search"

const Storage = lazy(() => import("./content/Storage"))
const Urls = lazy(() => import("./content/Urls"))
const Plan = lazy(() => import("./content/Plan"))
const Admin = lazy(() => import("./content/Admin"))

const CHECK_CONNECTION_INTERVAL = 4000

const RESULT_LIMIT = 5

const paths = [
    { action: "navigate", title: "Storage", path: "/storage", icon: "folder" },
    { action: "navigate", title: "Files", path: "/storage", icon: "file" },
    { action: "navigate", title: "Folders", path: "/storage", icon: "folder" },
    { action: "navigate", title: "Urls", path: "/urls", icon: "link" },
    { action: "navigate", title: "Links", path: "/urls", icon: "link" },
    { action: "navigate", title: "Redirects", path: "/urls", icon: "link" },
    { action: "navigate", title: "Plan", path: "/plan", icon: "key" }
]

const subpaths = [
    { action: "action", title: "Upload File", path: "/storage/upload", icon: "upload" },
    { action: "action", title: "Download File", path: "/storage/download", icon: "download" },
    { action: "action", title: "Create Url", path: "/urls/create", icon: "up-right-from-square" },
    { action: "action", title: "Url Stats", path: "/urls/stats", icon: "chart-line" }
]

interface State {
    connected: boolean,
    search: string,
    results: Result[]
}

export default class Dashboard extends Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            connected: true,
            search: "",
            results: this.fetchResults("")
        }
    }
    
    componentDidMount() {
        this.checkConnection()
        setInterval(() => {
            this.checkConnection()
        }, CHECK_CONNECTION_INTERVAL)
    }
    
    checkConnection = () => {
        fetch(getAPIUrl())
            .then(() => {
                if (!this.state.connected)
                    this.setState({ connected: true })
            })
            .catch(() => {
                if (this.state.connected)
                    this.setState({ connected: false })
            })
    }
    
    navigateTo = (path: string) => {
        window.location.href = path
    }
    
    fetchResults = (query: string) => {
        let includedPaths: string[] = []
        let results: Result[] = []
        for (let i = 0; i < paths.length; i++) {
            if (results.length >= RESULT_LIMIT) break
            if (paths[i].title.toLowerCase().includes(query.toLowerCase())) {
                if (includedPaths.includes(paths[i].path.toLowerCase())) continue
                includedPaths.push(paths[i].path.toLowerCase())
                results.push(paths[i])
                if (paths[i].path === "/") continue
                for (let j = 0; j < subpaths.length; j++) {
                    if (results.length >= RESULT_LIMIT) break
                    if (subpaths[j].path.toLowerCase().startsWith(paths[i].path.toLowerCase())) {
                        if (includedPaths.includes(subpaths[j].path.toLowerCase())) continue
                        includedPaths.push(subpaths[j].path.toLowerCase())
                        results.push(subpaths[j])
                    }
                }
            }
        }
        for (let i = 0; i < subpaths.length; i++) {
            if (results.length >= RESULT_LIMIT) break
            if (subpaths[i].title.toLowerCase().includes(query.toLowerCase())) {
                if (includedPaths.includes(subpaths[i].path.toLowerCase())) continue
                includedPaths.push(subpaths[i].path.toLowerCase())
                results.push(subpaths[i])
            }
        }
        return results
    }
    
    updateSearch = (query: string) => {
        this.setState({
            search: query,
            results: this.fetchResults(query)
        })
    }
    
    render() {
        return <Router>
            <div className={ "Dashboard" }>
                <header>
                    <h2 id={ "title" }>Dashboard</h2>
                    <div id={ "spacer" }/>
                    <div id={ "signout-button" }>
                        <img src={ this.props.user.picture } alt={ this.props.user.name }/>
                        <p>
                            <span id={ "name" }>{ this.props.user.name }</span>
                            <span id={ "email" }>{ this.props.user.email }</span>
                        </p>
                    </div>
                </header>
                
                <main>
                    { !this.state.connected && <div className={ "connection-error" }>
                        <div className={ "label" }>
                            <i className={ "fa-solid fa-exclamation-triangle" }></i>
                            Currently, the API is not available. Please wait a few seconds or try again later.
                        </div>
                    </div> }
                    
                    <nav>
                        <NavLink
                            to={ "/storage" }
                            className={ ({ isActive, isPending }) =>
                                "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "")
                            }
                            data-hoverable={ "true" }>
                            <div className={ "label row" }>
                                <i className={ "fa-solid fa-folder" }></i>
                                Storage
                                <i className={ "fa-solid fa-chevron-down" }></i>
                            </div>
                            <div className={ "submenu" }>
                                <NavLink
                                    to={ "/storage/upload" }
                                    className={ ({ isActive, isPending }) =>
                                        "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                                    data-hoverable={ "true" }>
                                    <div className={ "label row" }>
                                        <i className={ "fa-solid fa-upload" }></i>
                                        Upload
                                    </div>
                                </NavLink>
                                
                                <NavLink
                                    to={ "/storage/download" }
                                    className={ ({ isActive, isPending }) =>
                                        "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                                    data-hoverable={ "true" }>
                                    <div className={ "label row" }>
                                        <i className={ "fa-solid fa-download" }></i>
                                        Download
                                    </div>
                                </NavLink>
                            </div>
                        </NavLink>
                        
                        <NavLink
                            to={ "/urls" }
                            className={ ({ isActive, isPending }) =>
                                "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "")
                            }
                            data-hoverable={ "true" }>
                            <div className={ "label row" }>
                                <i className={ "fa-solid fa-link" }></i>
                                Urls
                                <i className={ "fa-solid fa-chevron-down" }></i>
                            </div>
                            <div className={ "submenu" }>
                                <NavLink
                                    to={ "/urls/create" }
                                    className={ ({ isActive, isPending }) =>
                                        "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                                    data-hoverable={ "true" }>
                                    <div className={ "label row" }>
                                        <i className={ "fa-solid fa-up-right-from-square" }></i>
                                        Create
                                    </div>
                                </NavLink>
                                
                                <NavLink
                                    to={ "/urls/stats" }
                                    className={ ({ isActive, isPending }) =>
                                        "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                                    data-hoverable={ "true" }>
                                    <div className={ "label row" }>
                                        <i className={ "fa-solid fa-chart-line" }></i>
                                        Stats
                                    </div>
                                </NavLink>
                            </div>
                        </NavLink>
                        
                        <NavLink
                            to={ "/plan" }
                            className={ ({ isActive, isPending }) =>
                                "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                            data-hoverable={ "true" }>
                            <div className={ "label row" }>
                                <i className={ "fa-solid fa-key" }></i>
                                Plan
                            </div>
                        </NavLink>
                        
                        <NavLink
                            to={ "/admin" }
                            className={ ({ isActive, isPending }) =>
                                "nav-item" + (isPending ? " nav-item-pending" : isActive ? " nav-item-active" : "") }
                            data-hoverable={ "true" }>
                            <div className={ "label row" }>
                                <i className={ "fa-solid fa-terminal" }></i>
                                Admin
                            </div>
                        </NavLink>
                        
                        <div id={ "spacer" }/>
                        
                        <a className={ "nav-item" }>
                            <div className={ "label row" }>&copy; loidlStorage</div>
                        </a>
                    </nav>
                    
                    <Routes>
                        <Route path={ "/" } element={ <Navigate to={ "/storage" }/> }/>
                        <Route path={ "/storage" }
                               element={ <Suspense><Storage user={ this.props.user }
                                                            token={ "google:" + this.props.user.sub }/></Suspense> }/>
                        <Route path={ "/urls" }
                               element={ <Suspense><Urls user={ this.props.user }
                                                         token={ "google:" + this.props.user.sub }/></Suspense> }/>
                        <Route path={ "/urls/create" }
                               element={ <Suspense><CreateUrl user={ this.props.user }
                                                              token={ "google:" + this.props.user.sub }/></Suspense> }/>
                        <Route path={ "/plan" }
                               element={ <Suspense><Plan user={ this.props.user }
                                                         token={ "google:" + this.props.user.sub }/></Suspense> }/>
                        <Route path={ "/admin" }
                               element={ <Suspense><Admin user={ this.props.user }
                                                          token={ "google:" + this.props.user.sub }/></Suspense> }/>
                    </Routes>
                </main>
            </div>
            
            <Search
                title="Navigate to"
                results={ this.state.results }
                navigateTo={ this.navigateTo }
                updateSearch={ this.updateSearch }/>
        </Router>
    }
}