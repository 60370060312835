import { Component } from "react"

interface FileStorageFile {
    path: string,
    name: string,
    size: number,
    owner: string
}

interface ShareResponse {
    key: string,
    file: FileStorageFile | null
}

export default class Share extends Component<any, { shareResponse: ShareResponse }> {
    constructor(props: any) {
        super(props)
        this.state = {
            shareResponse: {
                key: "",
                file: null
            }
        }
    }
    
    render() {
        return <div className={ "container" }>
            <i className={ "fa-solid fa-share" }/>
            <h1>Share</h1>
            { this.state.shareResponse.file === null ? <p>File not found</p> : <>
                <p>File found JEAHHAHH</p>
            </> }
        </div>
    }
}