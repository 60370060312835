import React, { Component } from "react"
import "./Search.scss"

const SEARCH_POPUP_ANIMATION_DELAY = 100

export interface Result {
    action?: string,
    title: string,
    path: string,
    icon?: string
}

interface Props {
    title: string,
    results: Result[],
    navigateTo: (path: string) => void,
    updateSearch: (query: string) => void
}

interface State {
    show: boolean,
    selected: number
}

export default class Search extends Component<Props, State> {
    keyframes = [
        { opacity: 0 },
        { opacity: 1 }
    ]
    
    constructor(props: any) {
        super(props);
        this.state = {
            show: false,
            selected: -1
        }
    }
    
    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown)
    }
    
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown)
    }
    
    handleKeyDown = (event: any) => {
        if (event.ctrlKey && event.key === 'k') {
            event.preventDefault()
            this.toggle()
        } else if (event.key === 'Escape') {
            if (this.state.show) {
                event.preventDefault()
                this.hide()
            }
        } else if (event.key === 'ArrowDown' || (!event.shiftKey && event.key === 'Tab')) {
            if (this.state.show) {
                event.preventDefault()
                if (this.state.selected < this.props.results.length - 1) {
                    this.setState({ selected: this.state.selected + 1 })
                }
            }
        } else if (event.key === 'ArrowUp' || (event.shiftKey && event.key === 'Tab')) {
            if (this.state.show) {
                event.preventDefault()
                if (this.state.selected > -1) {
                    this.setState({ selected: this.state.selected - 1 })
                }
            }
        } else if (event.key === 'Enter') {
            if (this.state.show) {
                event.preventDefault()
                if (this.state.selected > -1) {
                    this.props.navigateTo(this.props.results[this.state.selected].path)
                    this.hide()
                }
            }
        }
    }
    
    toggle() {
        if (this.state.show) this.hide()
        else this.show()
    }
    
    show() {
        document.body.style.userSelect = "none"
        this.setState({ show: true, selected: -1 })
        
        const searchWrapper = document.querySelector("#search-wrapper") as HTMLElement | null
        if (searchWrapper) searchWrapper.style.display = "block"
        
        document.querySelector("#search-wrapper")?.animate(this.keyframes, {
            duration: SEARCH_POPUP_ANIMATION_DELAY,
            easing: "ease-in-out",
            fill: "forwards"
        })
        
        const search = document.querySelector("#search") as HTMLInputElement | null
        search?.focus()
        search?.addEventListener("focusout", () => {
            if (this.state.show) this.hide()
        })
    }
    
    hide() {
        document.body.style.userSelect = "auto"
        this.setState({ show: false })
        
        document.querySelector("#search-wrapper")?.animate(this.keyframes, {
            duration: SEARCH_POPUP_ANIMATION_DELAY,
            easing: "ease-in-out",
            fill: "forwards",
            direction: "reverse"
        }).finished.then(() => {
            const searchWrapper = document.querySelector("#search-wrapper") as HTMLElement | null
            if (searchWrapper) searchWrapper.style.display = "none"
        })
    }
    
    render() {
        return <div id={ "search-wrapper" }>
            <div id={ "search-bar" }
                 data-selected={ this.state.selected === -1 }
                 onMouseOver={ () => this.setState({ selected: -1 }) }>
                <i className={ "fas fa-globe" }/>
                <p>{ this.props.title }</p>
                <input type={ "text" }
                       id={ "search" }
                       placeholder={ "" }
                       onChange={ (e) => {
                           this.props.updateSearch(e.target.value)
                           this.setState({ selected: -1 })
                       } }/>
                <div id={ "search-results" }>
                    { this.props.results.map((result, index) => {
                        return <div className={ "search-result" }
                                    key={ index }
                                    data-selected={ this.state.selected === index }
                                    onMouseOver={ () => this.setState({ selected: index }) }
                                    onClick={ () => this.props.navigateTo(result.path) }
                                    style={ (this.props.results.some(result => result.action !== "action") && result.action === "action") ? {
                                        marginLeft: "42px"
                                    } : {} }>
                            <i className={ "fas fa-" + (result.icon ?? "compass") }/>
                            <p>
                                { result.title }
                                { result.action && <i className={ "action" }>{ " " + result.action }</i> }
                            </p>
                        </div>
                    }) }
                </div>
            </div>
        </div>
    }
}