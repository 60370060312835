import React, { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import Dashboard from "./dashboard/Dashboard"
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default function App() {
    const [user, setUser] = useState<any>({})
    
    function handleCallbackResponse(response: any) {
        let userObject = jwt_decode(response.credential)
        setUser(userObject)
        cookies.set('user', userObject, { path: '/' })
    }
    
    useEffect(() => {
        if (cookies.get('user')) {
            setUser(cookies.get('user'))
            return
        }
        
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            // @ts-ignore
            google?.accounts.id.initialize({
                client_id: "672979283537-e3i8hs3eluimc77laa0nl6ru9gpr5g9l.apps.googleusercontent.com",
                callback: handleCallbackResponse
            })
            
            // @ts-ignore
            google?.accounts.id.renderButton(
                document.getElementById('google-signin-button'),
                { theme: "outline", size: "large", longtitle: true }
            )
        };
        document.body.appendChild(script);
    }, []);
    
    return (
        <div className="App">
            { Object.keys(user).length > 0
                ?
                <Dashboard user={ user }/>
                :
                <div className={ "container" } id={ "login-container" }>
                    <i className={ "fa-solid fa-right-to-bracket" }/>
                    <h1>Dashboard Authentication</h1>
                    <p>Please log in with your Google Account</p>
                    <div id={ "google-signin-button" }></div>
                </div>
            }
        </div>
    )
}