import React, { Component } from "react"
import Form from "../components/Form"
import { getAPIUrl } from "../../util/API"

interface State {
    type: "redirect" | "iframe",
    destination: string
}

export default class CreateUrl extends Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            type: "redirect",
            destination: ""
        }
    }
    
    createUrl = () => {
        fetch(
            encodeURI(
                getAPIUrl()
                + "urls/create-url?loginToken="
                + this.props.token
                + "&type=" + this.state.type
                + "&destination=" + this.state.destination
            )
        ).then(response => response.json())
         .then(data => {
             if (data.status === "ok") alert("Url created successfully!")
             else alert("API Failed to create url: " + data.error)
         })
         .catch(error => {
             console.error(error)
             alert("Failed to create url.")
         })
    }
    
    render() {
        return (
            <div className={ "content" } style={ { maxWidth: "500px", margin: "0 auto" } }>
                <h3 className={ "label" }>
                    <i className={ "fa-solid fa-up-right-from-square" }></i>
                    Create an Url
                </h3>
                
                <section data-styled={ "" }>
                    <Form action={ "" }
                          method={ "" }
                          onSubmit={ () => {
                          } }
                          sections={ [
                              {
                                  title: "Create a new shortened Url",
                                  elements: [
                                      {
                                          placeholder: "Type",
                                          icon: "fa-solid fa-hand-point-right",
                                          buttons: [
                                              {
                                                  text: "Redirect",
                                                  icon: "fa-solid fa-location-arrow",
                                                  isActive: this.state.type === "redirect",
                                                  onClick: () => this.setState({ type: "redirect" })
                                              }, {
                                                  text: "iFrame",
                                                  icon: "fa-solid fa-crop-simple",
                                                  isActive: this.state.type === "iframe",
                                                  onClick: () => this.setState({ type: "iframe" })
                                              }
                                          ]
                                      }, {
                                          html: <ul>
                                              <li><strong>Redirect:</strong> Redirects the user to the destination URL.
                                              </li>
                                              <li><strong>iFrame:</strong> Displays the destination URL in an iFrame.
                                              </li>
                                          </ul>
                                      }, {
                                          type: "url",
                                          placeholder: "Destination",
                                          icon: "fa-solid fa-link",
                                          label: "destination",
                                          onChange: (update) => this.setState({ destination: update })
                                      }, {}, {
                                          text: "Submit",
                                          onClick: this.createUrl
                                      }
                                  ]
                              }
                          ] }/>
                </section>
            </div>
        )
    }
}