import React, { Component } from "react"
import "./Form.scss"

interface Section {
    title: string,
    elements: (Input | Segment | Button | Spacer)[]
}

interface Input {
    type: string,
    placeholder: string,
    icon: string,
    label: string,
    onChange: (update: string) => void
}

interface Button {
    text: string,
    icon?: string,
    isActive?: boolean,
    onClick: () => void
}

interface Segment {
    placeholder: string,
    icon: string,
    buttons: Button[]
}

interface Spacer {
    html?: JSX.Element
}

interface Props {
    action: string,
    method: string,
    onSubmit: (event: any) => void,
    sections: Section[]
}

export default class Form extends Component<Props, any> {
    renderSpacer = (spacer: Spacer, index: number) => {
        return <div className={ "form-spacer" } key={ index }>
            { spacer.html }
        </div>
    }
    
    renderButton = (button: Button, index: number) => {
        let className = "form-button"
        if (button.isActive ?? false) className += " button-active"
        return <button className={ className }
                       onClick={ button.onClick }
                       key={ index }>
            { button.icon ? <i className={ button.icon }/> : null }
            { button.text }
        </button>
    }
    
    renderSegment = (segment: Segment, index: number) => {
        return <div className={ "form-segment" } key={ index }>
            <div className={ "buttons" }>
                <div>
                    <i className={ segment.icon }/>
                    <p>{ segment.placeholder }</p>
                </div>
                
                { segment.buttons.map((button, buttonIndex) => {
                    return this.renderButton(button, buttonIndex)
                }) }
            </div>
        </div>
    }
    
    renderInput = (input: Input, index: number) => {
        return <div className={ "form-input" }
                    data-placeholder={ input.placeholder }
                    key={ index }>
            
            <div className={ "border-left" }/>
            <div className={ "border-right" }/>
            <div className={ "border-bottom" }/>
            
            <input type={ input.type } name={ input.label } id={ input.label }
                   onChange={ e => input.onChange(e.currentTarget.value) }/>
            
            <i className={ input.icon }/>
        
        </div>
    }
    
    renderElement = (element: Input | Segment | Button | Spacer, index: number) => {
        if ("type" in element) return this.renderInput(element, index)
        else if ("buttons" in element) return this.renderSegment(element, index)
        else if ("text" in element) return this.renderButton(element, index)
        else return this.renderSpacer(element, index)
    }
    
    renderSection = (section: Section, index: number) => {
        return <div className={ "form-section" } key={ index }>
            <p className={ "form-section-title" }>{ section.title }</p>
            
            { section.elements.map((element, elementIndex) => {
                return this.renderElement(element, elementIndex)
            }) }
        
        </div>
    }
    
    render() {
        return <form className={ "form" }
                     action={ this.props.action }
                     method={ this.props.method }
                     onSubmit={ (e) => {
                         e.preventDefault()
                         this.props.onSubmit(e)
                     } }>
            
            { this.props.sections.map((section, sectionIndex) => {
                return this.renderSection(section, sectionIndex)
            }) }
        
        </form>
    }
}