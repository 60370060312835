import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import Share from "./Share"
import App from "./App"

const params = new URLSearchParams(window.location.search)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

if (params.has('share')) {
    root.render(
        <React.StrictMode>
            <Share share={ params.get('share') }/>
        </React.StrictMode>
    )
} else {
    root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    )
}